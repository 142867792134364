import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { styled } from "@mui/system"
import PropTypes from "prop-types"
import React from "react"

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
}))

const ProgramDetails = ({ programName, assignmentName }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Stack spacing={4}>
      <StyledTypography variant={isMobile ? "h6" : "h5"} component="h1">
        {programName}
      </StyledTypography>
      {assignmentName && (
        <Typography
          variant={isMobile ? "subtitle1" : "h6"}
          color="text.primary"
          component="h2"
        >
          {assignmentName}
        </Typography>
      )}
    </Stack>
  )
}

ProgramDetails.propTypes = {
  programName: PropTypes.string.isRequired,
  assignmentName: PropTypes.string,
}

export default ProgramDetails
