import { humanizeString } from "@/helpers/formatters"
import { CardContent, CardHeader, Link, Stack, styled, Typography } from "@mui/material"
import React from "react"
import ProgramDetails from "../ProgramDetails"
import { StyledButton, StyledCard } from "../StyledComponents"
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"

const ActivityWrapper = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  minWidth: "288px",
  minHeight: "88px",
  borderRadius: "8px",
  border: `1px solid ${theme.palette.btBlue[30]}`,
  backgroundColor: theme.palette.btBlue[5],
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  marginTop: theme.spacing(3),
}))

const NotificationIcon = styled(NotificationsActiveIcon)(({ theme }) => ({
  color: theme.palette.lockerOrange[50],
  fontSize: 22,
}))

const ActivitySection = () => (
  <ActivityWrapper>
    <NotificationIcon />
    <Typography>
      Students will have a <b>“Start Activity” button</b> in this panel on their screens.
    </Typography>
  </ActivityWrapper>
)

const LessonResource = ({ lesson, index }) => (
  <Link
    component="a"
    key={`${lesson.resource_url}-${index}`}
    href={lesson.resource_url}
    target="_blank"
    rel="noopener noreferrer"
    variant="body1"
    underline="hover"
    color="primary"
    aria-label={`Lesson link: ${lesson.name}`}
  >
    <Typography variant="body1" component="span">
      {lesson.name || humanizeString(lesson.resource_type)}
    </Typography>
  </Link>
)

const heightFitContentSx = {
  minHeight: "350px",
  overflow: "auto",
  maxHeight: "690px",
}

export const LessonTutorView = ({ data }) => (
  <StyledCard
    sx={{
      ...heightFitContentSx,
    }}
  >
    <CardHeader title={<ProgramDetails programName={data.program_name} assignmentName={data.assignment_name} />} />
    <CardContent>
      {data.lesson_resources && data.lesson_resources.length > 0 ? (
        <Stack spacing={1}>
          {data.lesson_resources.map((lesson, index) => (
            <LessonResource key={`${lesson.resource_url}-${index}`} lesson={lesson} index={index} />
          ))}
          {data.activity_preview_url && (
            <Link
              component="a"
              key={data.activity_preview_url}
              href={data.activity_preview_url}
              target="_blank"
              rel="noopener noreferrer"
              variant="body1"
              underline="hover"
              color="primary"
              aria-label={`Activity link`}
            >
              <Typography variant="body1" component="span">
                Activity (use <b>only</b> for reference)
              </Typography>
            </Link>
          )}
        </Stack>
      ) : (
        <Typography variant="subtitle1" color="text.secondary">
          No lesson resources available.
        </Typography>
      )}

      {data.activity_preview_url && <ActivitySection />}
    </CardContent>
  </StyledCard>
)

export const LessonStudentView = ({ data }) => (
  <StyledCard
    sx={{
      ...heightFitContentSx,
      minHeight: "200px"
    }}
  >
    <CardHeader title={<ProgramDetails programName={data.program_name} assignmentName={data.assignment_name} />} />
    <CardContent>
      {data?.assignment_url ? (
        <StyledButton
          variant="contained"
          color="primary"
          href={data.assignment_url}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ marginTop: 2, width: "224px"}}
          aria-label={`Start ${data.assignment_name}`}
          disableElevation
        >
          START ACTIVITY
        </StyledButton>
      ) : (
        <Typography variant="subtitle1" color="text.secondary">
          No lessons available.
        </Typography>
      )}
    </CardContent>
  </StyledCard>
)
